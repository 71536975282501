<template>
  <div class="container">
    <div class="header">
      <div class="wrap clearfloat">
        <div class="headerLogo fl">
          <a :href="urlName"><img src="../assets/img/logo.png" alt=""></a>
        </div>
        <div class="nav_box_right fr">
          <div class="navRightT" style="line-height: 30px;padding-top: 6px;">
            <el-breadcrumb separator="|">
              <el-breadcrumb-item><a class="link" target="_blank" href="">OA协同办公平台</a></el-breadcrumb-item>
              <el-breadcrumb-item><a class="link" target="_blank" href="">计量支付平台</a></el-breadcrumb-item>
              <el-breadcrumb-item><a class="link" target="_blank" href="">工程建设信息化平台</a></el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="navRightB" style="text-align: right; margin-top: 10px; margin-bottom: 12px;">
            <el-input placeholder="请输入关键词" v-model="searchValue" class="input-with-select" size="small" style="width: 300px;">
              <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
            </el-input>
          </div>
        </div>
      </div>
      <div class="navGrop">
        <div class="wrap nav_box">
          <ul class="clearfloat">
            <li :class="{'active':active == 0}">
              <div class="nav_grop">
                <h2><router-link to="/">网站首页</router-link></h2>
              </div>
            </li>
            <li v-for="(item, index) in navListN" :key="index" :class="{'active':active == item.id}">
              <div
                class="nav_grop" 
                @mouseenter="navShow(item.id)"
                @mouseleave="navClose()">
                <h2><a :href="item.url">{{item.name}}</a></h2>
                <el-collapse-transition>
                  <div class="nav_down" v-show="navDown == item.id">
                    <div class="nav_down_wrap clearfloat" v-show="item.group_list.length != 0">
                      <p v-for="(item1, index1) in item.group_list" :key="index1">
                        <a :href="item1.url && item1.url != '' ? `${item1.url}` : `${urlName}list?id=${item1.id}&navName=${item.name}&groupName=${item1.name}`" v-if="item1.have_list == 0 && item1.is_link !== 1">{{item1.name}}</a>
                        <a :href="item1.url && item1.url != '' ? `${item1.url}` : `${urlName}detail?id=${item1.id}&navName=${item.name}&groupName=${item1.name}&noList=1`" v-if="item1.have_list == 1 && item1.is_link !== 1">{{item1.name}}</a>
                        <a :href="item1.url" target="_blank" v-if="item.name == '核心成员'">{{item1.name}}</a>
                        <a :href="item1.link_url" target="_blank" v-if="item1.is_link === 1">{{item1.name}}</a>
                      </p>
                    </div>
                  </div>
                </el-collapse-transition>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
    <!-- 友情链接 -->
    <div class="wrap">
        <div class="homeLink">
          <div class="homeNewsHd clearfloat">
            <span>友情链接</span>
          </div>
          <div class="sec_link">
            <ul class="sec_link_l clearfloat">
              <li><a href="http://www.mot.gov.cn/" target="_blank"><img src="../assets/img/link01.gif" alt=""></a></li>
              <li><a href="http://jtys.gansu.gov.cn/" target="_blank"><img src="../assets/img/link030.gif" alt=""></a></li>
              <li><a href="http://www.cngaosu.com/" target="_blank"><img src="../assets/img/link_gsw.png" alt=""></a></li>
              <li><a href="http://www.ghatg.com/" target="_blank"><img src="../assets/img/link_ghlgw.jpg" alt=""></a></li>
              <li><a href="https://www.gsgjj.cn/" target="_blank"><img src="../assets/img/link_jtjsjt.jpg" alt=""></a></li>
              <li><a href="" target="_blank"><img src="../assets/img/link_gssglw.png" alt=""></a></li>
              <li><a href="http://www.gs-highway.com/" target="_blank"><img src="../assets/img/link04.gif" alt=""></a></li>
              <li><a href="http://www.gsjtkys.com/" target="_blank"><img src="../assets/img/link_jtkxyjy.jpg" alt=""></a></li>
              <li><a href="http://www.gsjtzj.com" target="_blank"><img src="../assets/img/link_gczjj.png" alt=""></a></li>
              <li><a href="http://www.gsgaosu.com" target="_blank"><img src="../assets/img/link_gsglglj.png" alt=""></a></li>
              <li><a href="http://www.gs-ys.com/" target="_blank"><img src="../assets/img/link_dlysj.png" alt=""></a></li>
              <li><a href="http://www.gansu-hc.com/" target="_blank"><img src="../assets/img/link_jtghkcsjy.jpg" alt=""></a></li>
            </ul>
          </div>
        </div>
    </div>
    
    <div class="linkWeb">
      <div class="wrap">
        <el-row :gutter="15">
          <el-col :span="6">
            <h3 class="linkWebTit">行业网站</h3>
            <div class="linkWebInner">
              <p v-for="(item, index) in linkOption01" :key="index">
                <a :href="item.url" target="_blank">{{item.name}}</a>
              </p>
            </div>
          </el-col>
          <el-col :span="6">
            <h3 class="linkWebTit">公航旅集团站群</h3>
            <div class="linkWebInner">
              <p v-for="(item, index) in linkOption02" :key="index">
                <a :href="item.url" target="_blank">{{item.name}}</a>
              </p>
            </div>
          </el-col>
          <el-col :span="6">
            <h3 class="linkWebTit">其他网站</h3>
            <div class="linkWebInner">
              <p v-for="(item, index) in linkOption03" :key="index">
                <a :href="item.url" target="_blank">{{item.name}}</a>
              </p>
            </div>
          </el-col>
          <el-col :span="6">
            <h3 class="linkWebTit">联系我们</h3>
            <div class="linkWebInner">
              <p>
                <i class="el-icon-location-information"></i> 甘肃省兰州市城关区酒泉路213号
              </p>
              <p>
               <i class="el-icon-phone-outline"></i> 0931-8489835
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="footer">
      <!-- <div class="footerWrap">
        <div class="wrap">
          <el-row :gutter="30">
            <el-col :span="9">
              <div class="footerLeft">
                <div class="footerTitle">
                  <div class="name">网站导航</div>
                  <div class="line"></div>
                </div>
                <div class="footerCont">
                  <span class="footerLeftTxt"><router-link to="/swth">网站首页</router-link></span> <span class="footerLeftLine">|</span>
                  <span class="footerLeftTxt"><router-link to="${urlName}listPage">公司概况</router-link></span> <span class="footerLeftLine">|</span>
                  <span class="footerLeftTxt"><router-link to="${urlName}list">新闻中心</router-link></span> <span class="footerLeftLine">|</span>
                  <span class="footerLeftTxt"><router-link to="${urlName}party">党的建设</router-link></span> <br>
                  <span class="footerLeftTxt"><router-link to="${urlName}business">业务领域</router-link></span> <span class="footerLeftLine">|</span>
                  <span class="footerLeftTxt"><router-link to="${urlName}job">招贤纳士</router-link></span> <span class="footerLeftLine">|</span>
                  <span class="footerLeftTxt"><router-link to="${urlName}learning">学习园地</router-link></span>
                </div>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="footerCenter">
                <div class="footerTitle">
                  <div class="name">联系我们</div>
                  <div class="line"></div>
                </div>
                <div class="footerCont footerCenterCont clearfloat">
                  <div class="footerCenterInner footerCenterInnerF fl">
                    <div class="footerCenterIco">
                      <img src="../assets/img/footer_ico_tel.png" alt="">
                    </div>
                    <div class="footerCenterTxt">
                      <h3>0935-4122058</h3>
                      <p>周一至周日 8:00~18:00</p>
                    </div>
                  </div>
                  <div class="footerCenterInner fl">
                    <div class="footerCenterIco">
                      <img src="../assets/img/footer_ico_add.png" alt="">
                    </div>
                    <div class="footerCenterTxt">
                      <h3>甘肃省武威市民勤县城东工业集聚区纬四路南侧</h3>
                      <p>24小时在线为您服务</p>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="footerRight">
                <div class="footerTitle">
                  <div class="name">关注我们</div>
                  <div class="line"></div>
                </div>
                <div class="footerCont">
                  <div class="footerRightCont">
                    <img src="../assets/img/ewm_wx.png" alt="">
                    <img src="../assets/img/ewm.png" alt="">
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div> -->
      <div class="footerBotttom">
        版权所有 © 甘肃公路建设管理集团有限公司 <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">陇ICP备16000941号-1</a> All Rights Reserved. <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=62010202001999" target="_blank">甘公网安备 62010202001999号</a> 
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data(){
    return {
      searchValue: '',
      active: 0,
      navListN: [
        {
            "id":1,
            "name":"公司概况",
            "isIndex":0,
            "url": `${this.urlName}listPage`,
            "group_list":[
                {
                    "id":1,
                    "name":"公司简介",
                    "url": `${this.urlName}listPage`,
                    "nav_id":2,
                    "have_list":1,
                    "create_time":"2020-12-22T07:10:13.037Z"
                },
                {
                    "id":1,
                    "name":"领导成员",
                    "url": `${this.urlName}listPage?t=1`,
                    "nav_id":2,
                    "have_list":1,
                    "create_time":"2020-12-22T07:10:13.037Z"
                },
                {
                    "id":2,
                    "name":"组织机构",
                    "url": `${this.urlName}listPage?t=2`,
                    "nav_id":2,
                    "have_list":1,
                    "create_time":"2020-12-22T07:10:13.037Z"
                },
                {
                    "id":4,
                    "name":"资质荣誉",
                    "url": `${this.urlName}listPage?t=3`,
                    "nav_id":2,
                    "have_list":1,
                    "create_time":"2020-12-22T07:10:41.071Z",
                },
                {
                    "id":2,
                    "name":"大事记",
                    "url": `${this.urlName}listPage?t=4`,
                    "nav_id":2,
                    "have_list":0,
                    "create_time":"2020-12-22T07:10:23.364Z"
                },
            ]
        },
        {
            "id":2,
            "name":"新闻资讯",
            "isIndex":0,
            "url": `${this.urlName}list`,
            "group_list":[
                {
                    "id":7,
                    "name":"公司要闻",
                    "url": `${this.urlName}list`,
                    "nav_id":3,
                    "have_list":0,
                    "create_time":"2020-12-22T07:11:14.391Z"
                },
                {
                    "id":8,
                    "name":"基层动态",
                    "url": `${this.urlName}list?t=1`,
                    "nav_id":3,
                    "have_list":0,
                    "create_time":"2020-12-22T07:11:41.023Z"
                }
            ]
        },
        {
            "id":3,
            "name":"项目简介",
            "isIndex":0,
            "url": `${this.urlName}business`,
            "group_list":[
                {
                    "id":14,
                    "name":"项目概况",
                    "url": `${this.urlName}business`,
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:12:55.466Z"
                },
                {
                    "id":15,
                    "name":"项目掠影",
                    "url": `${this.urlName}business?t=1`,
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:13:02.484Z"
                }
            ]
        },
        {
            "id":4,
            "name":"党建之窗",
            "isIndex":0,
            "url": `${this.urlName}party`,
            "group_list":[
                {
                    "id":14,
                    "name":"党建工作",
                    "url": `${this.urlName}party`,
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:12:55.466Z"
                },
                {
                    "id":15,
                    "name":"纪检监察",
                    "url": `${this.urlName}party?t=1`,
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:13:02.484Z"
                },
                {
                    "id":16,
                    "name":"党史教育",
                    "url": `${this.urlName}party/edu`,
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:13:02.484Z"
                },
                {
                    "id":17,
                    "name":"群团工作",
                    "url": `${this.urlName}party?t=3`,
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:13:02.484Z"
                },
            ]
        },
        {
            "id":5,
            "name":"专题专栏",
            "isIndex":0,
            "url": `${this.urlName}special`,
            "group_list":[
                {
                    "id":20,
                    "name":"三抓三促专栏",
                    "url": `${this.urlName}special`,
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z"
                },
                {
                    "id":20,
                    "name":"不忘初心 牢记使命",
                    "url": `${this.urlName}special?t=1`,
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z"
                },
                {
                    "id":20,
                    "name":"两学一做",
                    "url": `http://lxyz.people.cn/`,
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z"
                },
                {
                    "id":20,
                    "name":"疫情防控",
                    "url": `${this.urlName}special?t=4`,
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z"
                },
                {
                    "id":20,
                    "name":"党史教育学习",
                    "url": `${this.urlName}special?t=3`,
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z"
                },
                {
                    "id":20,
                    "name":"主题教育专栏",
                    "url": `${this.urlName}special?t=5`,
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z"
                },
            ]
        },
        {
            "id":6,
            "name":"公示公告",
            "isIndex":0,
            "url": `${this.urlName}notice`,
            "group_list":[
                {
                    "id":20,
                    "name":"招标公告",
                    "url": `${this.urlName}notice`,
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z"
                },
                {
                    "id":20,
                    "name":"通知公告",
                    "url": `${this.urlName}notice?t=1`,
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z"
                },
            ]
        },
        {
            "id":7,
            "name":"政策法规",
            "isIndex":0,
            "url": `${this.urlName}police`,
            "group_list":[
                {
                    "id":20,
                    "name":"相关法规",
                    "url": `${this.urlName}police`,
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z"
                },
                {
                    "id":20,
                    "name":"行业规范",
                    "url": `${this.urlName}police?t=1`,
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z"
                },
            ]
        },
        {
            "id":9,
            "name":"精神文明",
            "isIndex":0,
            "url": `${this.urlName}spiritual`,
            "group_list":[
                {
                    "id":20,
                    "name":"精神文明创建",
                    "url": `${this.urlName}spiritual`,
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z"
                },
                {
                    "id":20,
                    "name":"企业文化",
                    "url": `${this.urlName}spiritual?t=1`,
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z"
                },
                {
                    "id":20,
                    "name":"宣传片",
                    "url": `${this.urlName}spiritual?t=2`,
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z"
                },
            ]
        },
        {
            "id":8,
            "name":"关于我们",
            "isIndex":0,
            "url": `${this.urlName}about`,
            "group_list":[
            ]
        }
      ],
      navDown: 0,
      linkOption01: [
        {
          name: '交通运输部',
          url: 'http://www.mot.gov.cn/'
        },
        {
          name: '交通运输厅',
          url: 'http://jtys.gansu.gov.cn/'
        },
        {
          name: '质量监督局',
          url: 'http://www.gsjtzj.com/'
        },
        {
          name: '公路管理局',
          url: ''
        },
        {
          name: '甘肃财政厅',
          url: 'http://czt.gansu.gov.cn/'
        }
      ],
      linkOption02: [
        {
          name: '甘肃省公航旅集团',
          url: 'http://www.ghatg.com/'
        },
        {
          name: '甘肃长达路业公司',
          url: 'http://www.gscd.com.cn/'
        },
        {
          name: '甘肃远大路业公司',
          url: 'https://www.ghatg.com/yd/'
        },
        {
          name: '甘肃路桥投资公司',
          url: 'http://www.gs-lqtz.com/index.html'
        },
        {
          name: '甘肃金融资本公司',
          url: 'https://www.gsjrzb.cn/'
        }
      ],
      linkOption03: [
        {
          name: '中国交通新闻网',
          url: 'http://www.zgjtb.com/'
        },
        {
          name: '中国交通技术网',
          url: 'http://www.tranbbs.com/'
        },
        {
          name: '中国人民交通网',
          url: 'http://www.rmjtxw.com/'
        },
        {
          name: '中国公路招标网',
          url: 'http://www.roadbid.cn/'
        },
        {
          name: '中国高速公路网',
          url: 'http://www.cngaosu.com/'
        },
      ],
    }
  },
  mounted(){
    this.getPath()
  },
  created(){

  },
  watch:{
    '$route':'getPath'
  },
  methods: {
    // 二级导航展开
    navShow(d){
      let _this = this
      // _this[d] = true
      _this.navDown = d
    },
    // 二级导航收起
    navClose(){
      let _this = this
      // _this[d] = false
      _this.navDown = 0
    },
    haddleNav(index, params){
      var _this = this

      _this.active = index
      _this.$router.push(params.url)
    },
    getPath(){
      var _this = this

      _this.active = _this.$route.meta.index
    },
    search() {
      if (this.searchValue.split(" ").join("").length == 0){
        this.$message({
          message: '请输入关键词搜索！',
          type: 'warning'
        });
      }else{
        window.location.href=this.urlName + 'search?title=' + this.searchValue
      }
    }
  }
}
</script>
<style>
.header {
  padding-top: 15px;
  text-align: center;
  /* background-image: url(../assets/img/header_bg.png); */
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
}
.headerLogo {
  margin-top: 4px;
  /* margin-bottom: 20px; */
}
.headerLogo img {
  /* width: 360px; */
  height: 56px;
  width: auto;
  vertical-align: middle;
}
.headerNavList {
  padding-bottom: 18px;
}
.headerNavList li {
  float: left;
  text-align: center;
  width: 12.5%;
  position: relative;
  line-height: 20px;
  font-size: 16px;
}
.headerNavList li:last-child .line {
  display: none;
}
.headerNavTxt {
  display: inline-block;
  min-width: 45px;
  color: #000;
  letter-spacing: 4px;
  cursor: pointer;
}
.headerNavTxt:hover {
  color: #BC8F68;
}
.headerNavList .line {
  position: absolute;
  width: 12px;
  height: 2px;
  background-color: #d3d3d3;
  top: 50%;
  margin-top: -1px;
  right: -6px;
}
.headerNavList li.active .headerNavTxt {
  font-weight: bold;
  color: #BC8F68;
}
.headerNavList li.active::after {
  display: block;
  width: 46px;
  height: 18px;
  content: "";
  position: absolute;
  bottom: -18px;
  left: 50%;
  margin-left: -23px;
  background-image: url(../assets/img/header_nav_ico.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.main {
  min-height: 500px;
}
.footer {
  background: #282E31;
  color: #9D9D9D;
}
.footerWrap {
  padding: 60px 0 40px;
  text-align: left;
}
.footerTitle {
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  margin-bottom: 30px;
}
.footerTitle .line {
  width: 26px;
  height: 2px;
  background: #fff;
  margin-top: 8px;
}
.footerLeftTxt {
  display: inline-block;
  min-width: 56px;
  vertical-align: middle;
}
.footerLeftTxt a {
  color: #9D9D9D;
}
.footerLeftTxt a:hover {
  color: #fff;
}
.footerLeftLine {
  display: inline-block;
  margin: 0 20px;
  vertical-align: middle;
}
.footerLeft .footerCont {
  line-height: 34px;
}
.footerCenterIco {
  display: inline-block;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  margin-right: 4px;
  line-height: 36px;
}
.footerCenterIco img {
  width: 90%;
  height: auto;
  vertical-align: middle;
}
.footerCenterTxt {
  display: inline-block;
  vertical-align: middle;
  line-height: 22px;
}
.footerCenterTxt h3 {
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
}
.footerCenterTxt p {
  font-size: 14px;
}
.footerCenterCont {
  padding-top: 6px;
}
.footerCenterInnerF {
  margin-right: 10px;
  margin-bottom: 10px;
}
.footerRightCont {
  display: inline-block;
  vertical-align: top;
  text-align: center;
}
.footerRightCont img {
  width: 90px;
  height: auto;
  margin-top: -6px;
}
.footerRightCont img:first-child {
  margin-right: 10px;
}
.footerRightCont p {
  font-size: 12px;
  color: #BFC1C2;
  margin-top: 10px;
}
.footerBotttom {
  font-size: 14px;
  color: #9D9D9D;
  line-height: 50px;
  border-top: 1px solid #9D9D9D;
}
.footerBotttom a {
  color: #9D9D9D;
}
.footerBotttom a:hover {
  color: #fff;
}
.navGrop {
  background-color: #0077d4;
}
.nav_box {
    /* height: 38px; */
    /* background-color: #1970b9; */
    line-height: 38px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    /* margin: 0 10px; */
  }
  .nav_box ul li {
    height: 52px;
    line-height: 52px;
    float: left; 
    width: 10%; 
    color: #fff;
    text-align: center;
    position: relative;
  }
  /* .nav_box ul li a {
    display: block;
  } */
  .nav_grop {
    /* width: 82px; */
    height: 100%;
    /* margin: 5px auto 0; */
    position: relative;
  }
  .nav_box ul li h2 {
    text-align: center;
    /* line-height: 50px; */
    height: 100%;
    font-size: 16px;
    /* border-radius: 4px; */
    /* border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; */
    overflow: hidden;
    /* border: 1px solid #1970b9; */
  }
  .nav_box ul li h2 a {
    display: block;
    color: #fff;
  }
  .nav_grop:hover h2 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .nav_grop:hover h2 a {
    color: #1970b9;
    background-color: #fff;
    font-weight: 600;
  }
  .nav_box ul li.active h2 a {
    /* color: #1970b9; */
    background-color: #c60017;
    font-weight: 600;
  }
  .nav_down {
    position: absolute;
    width: 100%;
    left: 0;
    top: 52px;
    z-index: 55;
  }
  .nav_down_wrap {
    padding: 10px 0;
    /* border: 1px solid rgb(35, 96, 176); */
    border-top: none;
    background-color: #fff;
  }
  .nav_down p {
    line-height: 36px;
    text-align: center;
    font-size: 16px;
  }
  .nav_down p a {
    color: #333;
  }
  .nav_down p a:hover {
    color: #1970b9;
    text-decoration: underline;
  }
  .header_hd {
    height: 34px;
    line-height: 34px;
    /* background-image: url("../assets/img/st_03.jpg"); */
    background-repeat: repeat-x;
    background-size: auto 100%;
    padding: 0 25px;
    font-size: 12px;
  }
  .header_top {
    padding: 10px;
  }
  .mobile {
    display: block;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
    border: 1px solid #dedede;
    margin-top: 6px;
    font-size: 12px;
    font-weight: 600;
  }

  .mobile:hover {
    color: #d60f0f;
  }
  .user_input {
    display: inline-block;
    width: 120px;
  }
  .user_l {
    display: inline-block;
    margin-left: 10px;
  }
  .user_sure {
    margin: 0 4px 0 6px;
    height: 26px;
    line-height: 26px;
    padding: 0 10px;
    border: 1px solid #b6b6b6;
    cursor: pointer;
    vertical-align: top;
    margin-top: 3px;
    background-color: #e9e9e9;
    border-radius: 2px;
  }
  .user_sure:hover {
    background-color: #dddddd;
  }
  .user_forget:hover {
    color: #d60f0f;
    text-decoration: underline;
  }
  .mail_dsz {
    display: inline-block;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
    border: 1px solid #dedede;
    margin-top: 6px;
    font-size: 12px;
    color: #d60f0f;
    margin-left: 30px;
  }
  .mail_dsz:hover {
    border-color: #d60f0f;
  }
  .user_wrap {
    display: inline-block;
  }
  .footerBotttom {
    text-align: center;
  }
  .el-breadcrumb__inner a.link {
    font-weight: normal;
    font-size: 16px;
  }
  .el-breadcrumb__item:last-child .el-breadcrumb__inner a.link {
    cursor: pointer;
  }
  .el-breadcrumb__inner a.link:hover {
    color: #0077d4;
  }
  .el-breadcrumb__item:last-child .el-breadcrumb__inner a.link:hover {
    color: #0077d4;
  }
  .navRightB .el-input__inner,
  .navRightB .el-button {
    border-radius: 20px;
  }
  .navRightB .el-input-group--append .el-input__inner, 
  .navRightB .el-input-group__prepend {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .navRightB .el-input-group__append,
  .navRightB .el-input-group__prepend {
    border-radius: 20px;
  }
  .navRightB .el-input-group--prepend .el-input__inner, 
  .navRightB .el-input-group__append {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  /* 友情链接 */
  .linkWeb {
    padding: 30px 0;
    background-color: #f6f6f6;
  }
  .linkWebTit {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  .linkWebInner {
    line-height: 24px;
  }
  .linkWebInner p {
    margin: 8px 0;
  }
  .linkWebInner p a {
    color: #666;
  }
  .linkWebInner p i {
    font-size: 17px;
  }
  .linkWebInner p a:hover {
    color: #0077d4;
  }
</style>